import React, { Component } from 'react'
import Macampembelajaran from './macam_pembelajaran'
import { Helmet } from 'react-helmet'

export default class Introduction extends Component {
  render() {
    return (
      <div>

      <Helmet>
      <title>Homes</title>
        {/* <meta name="description" content=" tutorial lengkap untuk pemula , tutorial unity untuk pemula , tutorial ms Office untuk pemula , tutorial corel draw untuk pemula , tutorial codeigniter untuk pemula " /> */}
      </Helmet>

        <section className="colorlib-about" >
          <div className="colorlib-narrow-content">
               <div className="row">
               <div className="col-md-12 col-md-offset-3 col-md-pull-3 timeline-label" >
               {/* <div className="col-md-12"> */}

             
                            <h1 style={{WebkitTextFillColor:'rgb(255, 81, 0)'}}>
                            Selamat Datang
                            <br/>
                            Di
                            <br/>
                            Dictionary Plus+
                            </h1>                            
                            <h3 style={{WebkitTextFillColor:'rgb(200, 70, 0)'}}><small>
                            tempat belajar berbagai hal
                            </small></h3>
                            </div>
                            
                      
               </div>
           </div>
        </section>
        {/* <br/>
        <br/> */}
        <br/>

        <div  className="col-md-4 col-md-offset-8 col-md-pull-0">
            {/* <div className="services color-1"> */}
              
                {/* <div  > */}
                <div  className="gcse-search"></div>                                                         
                {/* </div> */}
            {/* </div> */}
        </div>


        
        <Macampembelajaran/>
      </div>
    )
  }
}
