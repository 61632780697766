import React, { Component } from 'react'
import { Link } from 'react-router-dom';

function refreshPage(){
    setTimeout(()=>{
      window.location.reload(false);
    }, 500);
   
  }


export default class About extends Component {
  render() {
    return (
      <div >
        <section className="colorlib-about" >
          <div className="colorlib-narrow-content">
              <div className="row">
              <div className="col-md-12 col-md-offset-3 col-md-pull-3 timeline-label" >
                {/* <div className="col-md-12"> */}
                        {/* <p>Dictionary Plus+ menyediakan berbagai tutorial yang anda butuhkan</p>
                        <p>Satu website untuk segala kebutuhan anda</p> */}
                    </div>
                </div>
            </div>           
        </section>

        <br/>

        <section >
            <div className="colorlib-narrow-content">

             
            
                <div className="row">
                    <div className="col-md-12 col-md-offset-3 col-md-pull-3 timeline-label" >

                            <span className="heading-meta">Apa saja yang ada di Dictionary Plus+ ?</span>
                            <h2 className="colorlib-heading">Berikut adalah beberapa tutorial yang di sajikan di Dictionary Plus+</h2>
                    </div>
                    
                </div>

                <div className="row row-pt-md">   
                       
                    <div className="col-md-4 text-center">
                        <div className="services color-1">
                            <span className="icon">
                                <i className="icon-world" />
                            </span>
                            <div  id="colorlib-main-menu" className="desc">
                                <h3>Web Development</h3>
                            
                                    <Link to="/codeIgniter-4" className="nav-link" onClick={refreshPage} >
                                        CodeIgniter 4
                                    </Link>                       
                            </div>
                        </div>
                    </div>
                
                    <div className="col-md-4 text-center">
                        <div className="services color-2">
                            <span className="icon">
                                <i className="icon-puzzle" />
                                <i className="icon-puzzle" />
                            </span>
                            <div  id="colorlib-main-menu" className="desc">
                                <h3>Game Development</h3>
                                
                                    <Link to="/unity-3D" className="nav-link" onClick={refreshPage} >
                                        Unity 3D
                                    </Link>              
                            </div>                
                        </div>
                    </div>

                    <div className="col-md-4 text-center">
                    <div className="services color-3">
                    <span className="icon">
                        <i className="icon-data" />
                    </span>
                    <div className="desc">
                        <h3>Grafik Design</h3>
                            <Link to="/corel-draw" className="nav-link" onClick={refreshPage} >
                                        Corel Draw X6
                            </Link> 
                    </div>
                    </div>
                    </div>

                    <div className="col-md-4 text-center">
                    <div className="services color-4">
                    <span className="icon">
                        <i className="icon-data" />
                    </span>
                    <div className="desc">
                        <h3>Microsoft Office</h3>
                            <Link to="/microsoft-office-excel-2010" className="nav-link" onClick={refreshPage} >
                            Microsoft Office Exel 2010
                            </Link> 
                    </div>
                    </div>
                    </div>

                    <div className="col-md-4 text-center">
                    <div className="services color-5">
                    <span className="icon">
                        <i className="icon-data" />
                    </span>
                    <div className="desc">
                        <h3>Serba Serbi Teknologi</h3>
                            <Link to="/seputar-aplikasi-android" className="nav-link" onClick={refreshPage} >
                            Seputar Aplikasi Android
                            </Link> 
                    </div>
                    </div>
                    </div>

                    <div className="col-md-4 text-center">
                    <div className="services color-6">
                    <span className="icon">
                        <i className="icon-data" />
                    </span>
                    <div className="desc">
                        <h3>Pengetahuan</h3>
                            <Link to="/pengetahuan-umum" className="nav-link" onClick={refreshPage} >
                            Pengetahuan Umum
                            </Link> 
                    </div>
                    </div>
                    </div>
            
                </div>
            </div>
        </section>
      </div>
    )
  }
}
